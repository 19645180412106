@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.pagination {
  display: flex;
  justify-content: center;
}

.pagination li {
  display: inline;
  margin: 0 5px;
}

.pagination li a {
  padding: 3px 7px;
  cursor: pointer;
  font-size: 12px;
}

.pagination li.active a {
  border: 2px solid #663399;
  border-radius: 5px;
}

.recognition-history,
.wallet-history,
.recognition-sent-history,
.coupon-list {
  scrollbar-width: thin;
}
